import React from "react";
import {StyleSheet, Text, View} from "react-native";
import CustomTheme from "../../model/CustomTheme";


interface TrackingDataProps {
	theme: CustomTheme;
}

interface TrackingDataState {
	backgroundColor: string;
}

export default class TrackingData extends React.Component<TrackingDataProps, TrackingDataState> {



	constructor(props: TrackingDataProps) {
		super(props);
	}

	render() {
		const styles = StyleSheet.create({
			container: {
				flex: 1,
				backgroundColor: this.props.theme.secondColor,
			}
		});
		return (
			<View style={styles.container}>
				<Text>TrackingData</Text>
			</View>
		);
	}
}
