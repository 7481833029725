import * as React from 'react';
import MainLayout from './app/layout/MainLayout';


export default function App() {

  //const db = SQLite.openDatabase({ name: 'tagid.db', location: 'default' });

  return (
    <MainLayout />
  );
}

