import React from "react";
import {StyleSheet, View} from "react-native";
import BaseLayout from "../views/BaseLayout";
import TabLayout from "./TabLayout";
import CustomTheme from "../model/CustomTheme";
import CustomTabs from "../model/CustomTabs";
import {Feather, FontAwesome5, Ionicons} from "@expo/vector-icons";


interface MainViewProps {}

interface MainViewState {
	theme: CustomTheme;
	tabs: CustomTabs;
}

export default class MainLayout extends React.Component<MainViewProps, MainViewState> {

	sosTheme = new CustomTheme(
		'firebrick',
		'firebrick',
		'firebrick',
		'steelblue',
		'lightskyblue',
		'yellowgreen',
		'white',
		'black',
		'#989898',
		'#484848',
		'#e0e0e0',
		'rgba(0, 0, 0, 0)'
	);

	dogTheme = new CustomTheme(
		'firebrick',
		'firebrick',
		'firebrick',
		'steelblue',
		'lightskyblue',
		'bisque',
		'white',
		'black',
		'#989898',
		'#484848',
		'#e0e0e0',
		'rgba(0, 0, 0, 0)'
	);

	otherTheme = new CustomTheme(
		'firebrick',
		'firebrick',
		'firebrick',
		'steelblue',
		'lightskyblue',
		'antiquewhite',
		'white',
		'black',
		'#989898',
		'#484848',
		'#e0e0e0',
		'rgba(0, 0, 0, 0)'
	);

	sosTabs = new CustomTabs(
		'Medical Record',
		<Ionicons name="medical" size={18} color="black" />,
		'Life Tracking',
		<Feather name="activity" size={18} color="black" />,
		'Log Data',
		<FontAwesome5 name="file-medical-alt" size={18} color="black" />,
		'Position',
		<FontAwesome5 name="map" size={18} color="black" />
	);

	dogTabs = new CustomTabs(
		'Dog Record',
		<Ionicons name="medical" size={18} color="black" />,
		'Dog Tracking',
		<Feather name="activity" size={18} color="black" />,
		'Dog Log',
		<FontAwesome5 name="file-medical-alt" size={18} color="black" />,
		'Position',
		<FontAwesome5 name="map" size={18} color="black" />
	);

	otherTabs = new CustomTabs(
		'Record',
		<Ionicons name="medical" size={18} color="black" />,
		'Tracking',
		<Feather name="activity" size={18} color="black" />,
		'Log',
		<FontAwesome5 name="file-medical-alt" size={18} color="black" />,
		'Position',
		<FontAwesome5 name="map" size={18} color="black" />
)

	constructor(props: MainViewProps) {
		super(props);
		this.state = {
			theme: this.sosTheme,
			tabs: this.sosTabs
		};
	}

	render() {

		const style = StyleSheet.create({
			mainContainer: {
				flex: 8,
				flexDirection: "row",
				padding: 1,
			},
			baseContainer: {
				flex: 2,
				backgroundColor: this.state.theme.secondColor,
				padding: 20,
			},
			tabContainer: {
				flex: 6,
				borderWidth: 1,
				borderColor: this.state.theme.secondColor
			},
		});

		return (
			<View style={style.mainContainer}>
				<View style={style.baseContainer}>
					<BaseLayout theme={this.state.theme} updateTheme={this.switchThemeStyle} />
				</View>
				<View style={style.tabContainer}>
					<TabLayout theme={this.state.theme} tabs={this.state.tabs}/>
				</View>
			</View>
		);
	}

	switchThemeStyle = (theme:String) => {
		switch (theme) {
			case 'sos':
				this.setState({ theme: this.sosTheme, tabs: this.sosTabs });
				break;
			case 'dog':
				this.setState({ theme: this.dogTheme, tabs: this.dogTabs });
				break;
			case 'other':
				this.setState({ theme: this.otherTheme, tabs: this.otherTabs });
				break;
			default:
				this.setState({ theme: this.sosTheme, tabs: this.sosTabs });
		}
	};

}
