import React from "react";
import {StyleSheet, Text, View, TouchableOpacity, Picker} from "react-native";
import CustomTheme from "../../model/CustomTheme";
import DropDownPicker from 'react-native-dropdown-picker';

interface RecordDataProps {
	theme: CustomTheme;
}

interface RecordDataState {
	backgroundColor: string;
	selectedValue: string;
}

export default class RecordData extends React.Component<RecordDataProps, RecordDataState> {
	constructor(props: RecordDataProps) {
		super(props);
		this.state = {
			backgroundColor: this.props.theme.secondColor,
			selectedValue: 'exampleValue1', // Set default selected value
		};
	}

	render() {
		const styles = StyleSheet.create({
			container: {
				flex: 1,
				backgroundColor: this.props.theme.secondColor,
			},
			bar: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: this.props.theme.baseColor,
				paddingHorizontal: 16,
				paddingVertical: 8
			},
			addSign: {
				fontSize: 24,
				color: 'white',
			},
			dropdown: {
				width: 150, // Adjust width as needed
				zIndex: 10, // Set zIndex to ensure the dropdown is above other components
			},
		});

		return (
			<View style={styles.container}>
				<View style={styles.bar}>
					<Picker style={styles.dropdown}
									selectedValue={this.state.selectedValue}
									onValueChange={(item) => this.setState({ selectedValue: item.value })}
					>
						<Picker.Item label="SOS" value="sos" />
						<Picker.Item label="Dog" value="dog" />
						<Picker.Item label="Other" value="other" />
					</Picker>
					{/* Add sign */}
					<TouchableOpacity onPress={() => console.log("Add button pressed")}>
						<Text style={styles.addSign}>+</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}
