import React from "react";
import {NavigationContainer, Theme} from "@react-navigation/native";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import RecordData from "../views/tabs/RecordData";
import TrackingData from "../views/tabs/TrackingData";
import LogData from "../views/tabs/LogData";
import PositionData from "../views/tabs/PositionData";
import {StyleSheet, View} from "react-native";
import CustomTheme from "../model/CustomTheme";
import CustomTabs from "../model/CustomTabs";


interface TabViewProps {
	theme: CustomTheme;
	tabs: CustomTabs;
}

export default class TabLayout extends React.Component<TabViewProps> {

	styles = StyleSheet.create({
		tabContainer: {
			flex: 1,
		},
	});

	constructor(props: TabViewProps) {
		super(props);
	}

	render() {
		const Tab = createBottomTabNavigator();
		return (
			<NavigationContainer>
				<Tab.Navigator initialRouteName='position'>
					<Tab.Screen name='Position' options={{
						title: this.props.tabs.recordName,
						tabBarIcon: (tabInfo) => (
							this.props.tabs.recordIcon
						),
					}}>
						{(props) => <RecordData theme={this.props.theme} />}
					</Tab.Screen>

					<Tab.Screen name='tracking' options={{
						title: this.props.tabs.trackingName,
						tabBarIcon: (tabInfo) => (
							this.props.tabs.trackingIcon
						),
					}}>
						{(props) => <TrackingData theme={this.props.theme} />}
					</Tab.Screen>

					<Tab.Screen name='log' options={{
						title: this.props.tabs.logName,
						tabBarIcon: (tabInfo) => (
							this.props.tabs.logIcon
						),
					}}>
						{(props) => <LogData theme={this.props.theme} />}
					</Tab.Screen>

					<Tab.Screen name='position' options={{
						title: this.props.tabs.positionName,
						tabBarIcon: (tabInfo) => (
							this.props.tabs.positionIcon
						),
					}}>
						{(props) => <PositionData theme={this.props.theme} />}
					</Tab.Screen>
				</Tab.Navigator>
			</NavigationContainer>
		);
	}
}
