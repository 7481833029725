
export default class CustomTheme {

	alertColor: string
	alertPressedColor: string
	errorColor: string
	baseColor: string
	accentColor: string
	secondColor: string
	white: string
	black: string
	gray: string
	darkGray: string
	lightGray: string
	none: string


	constructor(
		alertColor: string,
		alertPressedColor: string,
		errorColor: string,
		baseColor: string,
		accentColor: string,
		secondColor: string,
		white: string,
		black: string,
		gray: string,
		darkGray: string,
		lightColor: string,
		none: string
	) {
		this.alertColor= alertColor;
		this.alertPressedColor= alertPressedColor;
		this.errorColor= errorColor;
		this.baseColor= baseColor;
		this.accentColor= accentColor;
		this.secondColor= secondColor;
		this.white= white;
		this.black= black;
		this.gray= gray;
		this.darkGray= darkGray;
		this.lightGray= lightColor;
		this.none= none;
	}
}
