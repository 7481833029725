import React, {ReactElement} from "react";

export default class CustomTabs {

	recordName: string
	recordIcon: ReactElement
	trackingName: string
	trackingIcon: ReactElement
	logName: string
	logIcon: ReactElement
	positionName: string
	positionIcon: ReactElement


	constructor(
		recordName: string,
		recordIcon: ReactElement,
		trackingName: string,
		trackingIcon: ReactElement,
		logName: string,
		logIcon: ReactElement,
		positionName: string,
		positionIcon: ReactElement
	) {
		this.recordName= recordName;
		this.recordIcon= recordIcon;
		this.trackingName= trackingName;
		this.trackingIcon= trackingIcon;
		this.logName= logName;
		this.logIcon= logIcon;
		this.positionName= positionName;
		this.positionIcon= positionIcon;
	}
}
