import React from "react";
import {StyleSheet, Text, View} from "react-native";
import CustomTheme from "../../model/CustomTheme";


interface LogDataProps {
	theme: CustomTheme;
}

interface LogDataState {
	backgroundColor: string;
}

export default class LogData extends React.Component<LogDataProps, LogDataState> {



	constructor(props: LogDataProps) {
		super(props);
	}

	render() {
		const styles = StyleSheet.create({
			container: {
				flex: 1,
				backgroundColor: this.props.theme.secondColor,
			}
		});
		return (
			<View style={styles.container}>
				<Text>LogData</Text>
			</View>
		);
	}
}
