import React from "react";
import { View, Text, Image, Button, TextInput, Picker, StyleSheet } from "react-native";
import { nanoid } from 'nanoid';
import {FontAwesome5} from "@expo/vector-icons";
import CustomTheme from "../model/CustomTheme";

interface BaseViewProps {
	theme: CustomTheme;
	updateTheme: (newValue: string) => void;
}

interface BaseLayoutState {
	selectedOption: string;
}

export default class BaseLayout extends React.Component<BaseViewProps, BaseLayoutState> {

	styles = StyleSheet.create({
		container: {
			flex: 1,
		},
		boldText: {
			fontWeight: 'bold',
		},
		avatar: {
			width: '100%',
			aspectRatio: 1,
			borderRadius: 50,
			marginBottom: 10,
		},
		centerContainer: {
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 10
		},
		buttonGap: {
			marginHorizontal: 10, // Adjust the gap as needed
		},
		passwordInput: {
			height: 50,
			borderColor: this.props.theme.lightGray,
			borderWidth: 1,
			marginTop: 10,
			marginBottom: 10,
		},
		dropdown: {
			height: 50,
			width: '100%',
		},
	});

	constructor(props: BaseViewProps) {
		super(props);
		this.state = {
			selectedOption: 'sos', // Default selected option
		};
	}

	render() {
		return (
			<View style={this.styles.container}>
				<View style={this.styles.centerContainer}>
					<Text style={this.styles.boldText}>{nanoid()}</Text>
				</View>
				{/* Replace 'path_to_your_avatar_image.jpg' with the correct path */}
				<View style={this.styles.centerContainer}>
					<Image source={require('../../assets/avatar.png')} style={this.styles.avatar} />
				</View>
				<View style={this.styles.centerContainer}>
					<FontAwesome5 name="upload" size={24} color="steelblue" onPress={() => {/* handle upload */}} />
					<View style={this.styles.buttonGap} /> {/* Add a gap between buttons */}
					<FontAwesome5 name="trash-alt" size={24} color="firebrick" onPress={() => {/* handle delete */}} />
				</View>
				<View style={this.styles.centerContainer}>
					<Text style={this.styles.boldText} >Last Changed: {new Date().toLocaleString()}</Text>
				</View>
				<TextInput style={this.styles.passwordInput} secureTextEntry={true} placeholder="Enter password" />
				<Picker style={this.styles.dropdown}
					selectedValue={this.state.selectedOption}
					onValueChange={(itemValue) => {
						this.state.selectedOption = itemValue
						this.props.updateTheme(itemValue)}
					}
				>
					<Picker.Item label="SOS" value="sos" />
					<Picker.Item label="Dog" value="dog" />
					<Picker.Item label="Other" value="other" />
				</Picker>
			</View>
		);
	}
}
